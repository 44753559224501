<template>
  <v-card class="card-letter">
    <v-row>
      <v-col cols="12" md="12" no-gutters class="py-0">
        <template v-if="type == 'dishe'">
          <router-link
            class="landing_primary--text card-info-link"
            :to="{
              name: 'dishe-details',
              params: { landing: $route.params.landing, id: item.id }
            }"
          >
            <v-img class="" :src="image" :lazy-src="image" aspect-ratio="1.4">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </router-link>
        </template>
        <template v-if="type == 'menu'">
          <router-link
            class="landing_primary--text card-info-link"
            :to="{
              name: 'menu-details',
              params: { landing: $route.params.landing, id: item.id }
            }"
          >
            <v-img class="" :src="image" :lazy-src="image" aspect-ratio="1.4">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </router-link>
        </template>
      </v-col>
      <v-col cols="12" md="12">
        <v-card-text class="pa-0 info-card">
          <template v-if="type == 'dishe'">
            <router-link
              class="landing_primary--text card-info-link"
              :to="{
                name: 'dishe-details',
                params: { landing: $route.params.landing, id: item.id }
              }"
            >
              <p class="description-letter  px-3 text-center landing_primary--text" v-text="name"></p>
            </router-link>
          </template>
          <template v-if="type == 'menu'">
            <router-link
              class="landing_primary--text card-info-link"
              :to="{
                name: 'menu-details',
                params: { landing: $route.params.landing, id: item.id }
              }"
            >
              <p class="description-letter px-3 landing_primary--text" v-text="name"></p>
            </router-link>
          </template>
          <template v-if="viewPrice">
            <template v-if="!allergen">
              <template
                v-for="(p,key) in prices">
                <div :key="key" class="text-center">
                <strong>{{p.name}}</strong><br>
                <v-chip-group mandatory active-class="primary--text" class="d-none d-md-block" v-if="p.price != null">
                  <div style="margin: auto">
                    <template >
                      <v-chip label>
                        {{p.price}}{{p.symbol}}
                      </v-chip>
                      <v-icon @click="addToCartModal(p.price, p.name)" color="blue" small>mdi-cart-plus</v-icon>
                    </template>

                  </div>
                </v-chip-group>
                </div>
              </template>
            </template>
            <template v-else>
              <v-row justify="center" no-gutters>
                <v-col cols="12" class="py-3">
                  <v-avatar
                    class="mx-1"
                    size="35"
                    v-for="(allergen, i) in item.allergens"
                    :key="i"
                  >
                    <img :src="urlAllergen + allergen.img" />
                  </v-avatar>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-card-text>

      </v-col>
    </v-row>
    <v-row justify="center">
    <v-dialog
      v-model="modalCant"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">{{this.product.name}}</v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            v-on:submit.prevent="validarCantidad"
            lazy-validation
          >
        <v-card-text>
          ¿Cuántos desea?
            <v-text-field v-model.number="cant_art" :rules="nameRules" required ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="green darken-1"
            text
            @click="validarCantidad"
          >
            Agregar
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="modalCant = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
         </v-form>
      </v-card>
    </v-dialog>
  </v-row>

  </v-card>
</template>
<script>
// import addToCard from './AddToCard'
import { mapGetters } from 'vuex'
export default {
  name: "CardDisheLetter",
  // components: { addToCard },
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true
    },
    type: {
      type: String,
      default: "",
      required: true
    },
    allergen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      url_img: process.env.VUE_APP_IMG_URL_API,
      urlAllergen: process.env.VUE_APP_IMG_ALERGENOS,
      product:{},
      modalCant:false,
      cant_art:0,
      nameRules: [
        v => !!v || 'Cantidad es requerido.',
        v => Number.isInteger(Number(v)) || "Debe ser un número."
      ],
      valid:true
    };
  },
    methods: {
    validarCantidad(){
      if (this.$refs.form.validate()) {
        this.addToCart();
        this.modalCant = false;
        this.cant_art = 0;
      }
    },
    addToCartModal(price,name){
      const item = {
        name : name,
        price : price,
        type : this.type
      }
      this.product = item;
      this.modalCant = true;
    },
    addToCart(){
      const item = {
        name : this.product.name,
        details : this.product.name,
        price : this.product.price,
        id:this.item.id,
        cant: Number.parseInt(this.cant_art),
        type : this.type,
        image : this.image,
        coin : this.coin_select,
        symbol:this.symbol
      }
      this.$store.commit('landing/ADD_ITEM_CART',item);
    }
  },
  computed: {
    ...mapGetters({
      coin_select: 'landing/coin',
      monedas:'landing/coins',
      symbol:'landing/symbol'
    }),
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "102px";
        case "sm":
          return "156px";
        case "md":
          return "204px";
        case "lg":
          return "306px";
        case "xl":
          return "306px";
      }
      return "102px";
    },
    coins(){
      return this.monedas;
    },
    name() {
      return this.item.name[this.$store.getters.language]
        ? this.item.name[this.$store.getters.language]
        : this.item.name.es;
    },
    redirect_details() {
      return {
        dishe: `restaurante/${this.$route.params.landing}/plato/${this.item.id}`,
        menu: `restaurante/${this.$route.params.landing}/menu/${this.item.id}`
      };
    },
    image() {
      return this.type == "dishe"
        ? `${this.url_img}images/dishes/${this.item.image_dish}`
        : this.type == "menu"
        ? `${this.url_img}images/menu/${this.item.image_menu}`
        : "";
    },
    prices() {
      if(this.item.price === undefined) return [];
      let coin = this.coin_select;
      let coins = JSON.parse(this.coins);
      let symbol = '';

      for (let e = 0; e < coins.length; e++) {
        const symbolCurrency = coins[e];
        if (symbolCurrency.cod == coin) {
          symbol = symbolCurrency.symbol;
        }
      }

      let price = JSON.parse(this.item.price);
      price.forEach(prices => {
        let p = prices.prices;
       for (let i = 0; i < p.length; i++) {
         const precio = p[i];
         if (precio.coin == coin) {
           prices.price = precio.amount;
           prices.symbol = symbol;
           prices.coin = precio.coin;
         }
       }
      });
      return price;
    },
    viewPrice() {
      if(this.type!=='menu') return true
      return this.item.show_price
    }
  },

  mounted() {
    this.coin = this.$store.state.coin;
  },
  watch:{

  }
};
</script>
<style lang="sass">
.title-letter
	font-size: 24px !important
	padding-bottom: 0
	font-weight: 400
	letter-spacing: 1px

.box-letter
	padding-bottom: 2rem
	margin-bottom: 2rem

.card-letter .v-image
	border-radius: 5px !important

.card-info-link
	text-decoration: none

.card-letter
	& .description-letter
			font-size: 1.2rem
			font-weight: 500
			line-height: 1.5rem
			padding-top: 12px
			padding-bottom: 12px
			margin: 0 !important
			letter-spacing: 1px
	& .price-letter
			font-size: 1.1rem
			font-weight: 700
			display: block
</style>
